import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const Stories = () => {
  const [allStories, setAllStories] = useState([]); 
  const [displayedStories, setDisplayedStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const storiesPerPage = 20;

  const urlParams = new URLSearchParams(location.search);
  const [page, setPage] = useState(parseInt(urlParams.get("page")) || 1);
  const [category, setCategory] = useState(urlParams.get("category") || 'All');

  useEffect(() => {
    fetchAllStories();
  }, [category]);

  useEffect(() => {
    updateDisplayedStories(allStories, search, page);
  }, [search, page, allStories]);

  const fetchAllStories = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/stories`);
      const stories = res.data;
      
      // Filter by category if needed
      const categoryFiltered = category === 'All' 
        ? stories 
        : stories.filter(story => story.categories.includes(category));
      
      setAllStories(categoryFiltered);
      updateDisplayedStories(categoryFiltered, search, 1);
    } catch (error) {
      console.error('Failed to fetch stories:', error);
      setAllStories([]);
    } finally {
      setLoading(false);
    }
  };

  const updateDisplayedStories = (stories, searchTerm, currentPage) => {
    let filtered = stories;
    if (searchTerm.trim()) {
      filtered = stories.filter(story =>
        story.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    const start = (currentPage - 1) * storiesPerPage;
    const paginatedStories = filtered.slice(start, start + storiesPerPage);
    setDisplayedStories(paginatedStories);
  };

  const handleNext = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    let query = `page=${nextPage}`;
    if (category !== 'All') {
      query += `&category=${category}`;
    }
    if (search.trim()) {
      query += `&search=${encodeURIComponent(search.trim())}`;
    }
    navigate(`/stories?${query}`);
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    const previousPage = page - 1;
    setPage(previousPage);
    let query = '';
    if (previousPage === 1) {
      if (category !== 'All' || search.trim()) {
        query = `?${category !== 'All' ? `category=${category}` : ''}${
          search.trim() ? `${category !== 'All' ? '&' : ''}search=${encodeURIComponent(search.trim())}` : ''
        }`;
      }
      navigate(`/stories${query}`);
    } else {
      query = `?page=${previousPage}${
        category !== 'All' ? `&category=${category}` : ''
      }${search.trim() ? `&search=${encodeURIComponent(search.trim())}` : ''}`;
      navigate(`/stories${query}`);
    }
    window.scrollTo(0, 0);
  };

  const handleCategory = (event) => {
    setCategory(event.target.value);
    setSearch('');
    setPage(1);
    let query = `page=1`;
    if (event.target.value !== 'All') {
      query += `&category=${event.target.value}`;
    }
    navigate(`/stories?${query}`);
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    setPage(1);
    let query = 'page=1';
    if (category !== 'All') {
      query += `&category=${category}`;
    }
    if (searchTerm.trim()) {
      query += `&search=${encodeURIComponent(searchTerm.trim())}`;
    }
    navigate(`/stories?${query}`);
  };

  const handleStoryClick = async (storyId) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/story?id=${storyId}`);
      navigate(`/stories/${storyId}`, {state: res.data});
    } catch (error) {
      console.error('Failed to fetch story details:', error);
    }
  };

  return (
    <div className='px-10 mt-12'>
      <h1 className='font-canela text-4xl pb-2'>Stories of Picturing Mexican America
        <select 
          onChange={handleCategory} 
          value={category} 
          className='pt-2 mt-1 inline-block align-middle text-base font-canela float-right p-1 border border-gray-300 rounded'
        >
          <option value="All">All</option>
          <option value="Crime">Crime</option>
          <option value="Culture">Culture</option>
          <option value="Events">Events</option>
          <option value="Families">Families</option>
          <option value="Food">Food</option>
          <option value="Holidays">Holidays</option>
          <option value="Music">Music</option>
          <option value="People">People</option>
          <option value="Places">Places</option>
          <option value="Politics & Social Justice">Politics & Social Justice</option>
          <option value="Women & Gender">Women & Gender</option>
        </select>
      </h1>

      <div className='flex justify-center my-6'>
        <div className='relative w-96'>
          <input 
            type="text" 
            placeholder="Search stories..."
            value={search}
            onChange={handleSearchChange}
            className='w-full px-4 py-2 border border-gray-300 rounded-full font-avenir focus:outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500 transition-colors'
          />
          {search && (
            <button 
              onClick={() => {
                setSearch('');
                setPage(1);
                navigate(category !== 'All' ? `/stories?category=${category}` : '/stories');
              }}
              className="absolute right-10 top-2.5 h-5 w-5 text-gray-400 hover:text-gray-600"
            >
              ×
            </button>
          )}
          <svg 
            className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" 
            fill="none" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth="2" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </div>
      </div>

      <div className='border-t border-gray-900 my-4 mb-12 w-auto'/>
      
      <div className='grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 gap-y-4 gap-x-2 pb-10'>
        {!loading ? (
          displayedStories.length > 0 ? (
            displayedStories.map((s) => (
              <button onClick={() => handleStoryClick(s._id)} key={s._id}>
                <div className='flex flex-col items-center'>
                  <img src={s.imageUrl || ''} alt="" className='max-h-48 w-auto'/>
                  <h1 className='font-canela p-1'>{s.title || ''}</h1>
                </div>
              </button>
            ))
          ) : (
            <div className='col-span-full text-center font-avenir'>
              No stories found
            </div>
          )
        ) : (
          <div className='h-96 col-span-full'>
            <p>loading...</p>
          </div>
        )}
      </div>

      <div className='border-t border-gray-900 mb-4 w-auto'/>
      <div className='flex justify-between pb-8'>
        {page === 1 ? (
          <div />
        ) : (
          <button onClick={handlePrevious} className='font-avenir text-gray-600 hover:underline'>PREVIOUS</button>
        )}
        {loading === false && displayedStories.length < storiesPerPage ? (
          <div />
        ) : (
          <button onClick={handleNext} className='font-avenir text-gray-600 hover:underline'>NEXT</button>
        )}
      </div>
    </div>
  );
};

export default Stories;
