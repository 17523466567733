import React from 'react'
import EmailSub from '../EmailSub'
import StoryOfWeek from '../widgets/StoryOfWeek'
// import Instagram from '../Instagram'

const Home = () => {

  return (
    <div>
      <div className="flex justify-center items-center">
        <img  src='/images/home1.png' alt=''/>
      </div>
      <div className='xs:px-10 md:px-32 mt-10'> 
        <h1 className='font-canela text-3xl md:text-4xl text-center mb-4'>Recovering LA’s Erased Mexican History</h1>
        <p className='font-avenir text-md text-center text-lg'>
          Welcome! Picturing Mexican America is a cluster of digital humanities projects
           designed and run by Professor Marissa López at UCLA. We’re looking back at the 
           history of Mexican Los Angeles to help us understand our present and undo the 
           systemic erasure of Los Angeles’ Mexican past &mdash; in a fun way!
          </p>
      </div>
        <div className=' h-116 bg-pma-green mt-10' />
        <div className='bg-white h-36 relative'>
          <div className='absolute -top-96 left-0 right-0'>
            <StoryOfWeek/>
          </div>
        </div>
      <div className='overflow-hidden relative flex items-center'>
        <img src='/images/home2crop.png' alt='' className='w-full object-cover'/>
          <div className="absolute inset-0 flex items-center justify-end mx-40">
            <div className="relative bg-white bg-opacity-80 text-center p-4 rounded w-1/3 h-1/5 md:min-w-[300px] md:min-h-[130px]">
              <a target="_blank" rel="noreferrer" href='https://mailchi.mp/8bb2d79e8e70/sign-up' className="flex hover:underline">
                <h1 className="text-left font-canela text-base md:text-xl pl-3 pr-1 pt-2">
                  Let’s Collaborate
                </h1>
                <img src='/icons/arrow-right.svg' alt='' className='w-8'></img>
              </a>
                  <p className="text-left text-sm md:text-base px-4 pb-2">
                    We’re always looking for writers, educators, and organizations to work with!
                  </p>
            </div>
          </div>
      </div>
      <div>
        <EmailSub/>
      </div>
    </div>
  )
}

export default Home
