import React from 'react'
//import { BsFiletypePdf } from 'react-icons/bs'

const Education = () => {

  return (
    <div>
      <div className='grid grid-cols-1 lg:grid-cols-2 mx-auto max-w-screen-lg px-10'>
        <div className='mt-5 mb-5 md:mt-10 md:mb-10 flex justify-center flex-col items-center'>
          <img src='/images/education.png' alt='' className='w-96 relative'/>
          <p className='font-avenir text-xs text-gray-700 mt-1'>Image Courtesy Sylvia Mendez School</p>
        </div>
        <div className='mt-5 md:mt-20 mb-10'>
          <h1 className='font-canela text-4xl'>Using Picturing Mexican America In The Classroom</h1>
          <p className='font-avenir'>
            Here you’ll find resources and teaching ideas for working with Picturing Mexican America 
            and related archival material. If you are a teacher interested in working with us or designing 
            assignments around our content, please use the “Contact” form to be in touch.
          </p>
        </div>
      </div>
      <div className='px-20 mt-5'>
        <h1 className='font-canela text-2xl'>Lesson Plans</h1>
        <div className='border-t border-gray-900 mb-6 w-auto'/>
        <h1 className='font-canela text-xl mb-1'>K-6</h1>
        <div className='flex flex-col'>
          <div className=' bg-pma-pink mb-5'>
            <div className='m-8'>
              <p className='mr-1 font-avenir text-sm'>Coming soon</p>
            </div>  
          </div>
        </div>
        <h1 className='font-canela text-xl mb-1 mt-2'>Middle School</h1>
        <div className='flex flex-col'>
          <div className=' bg-pma-pink mb-5'>
            <div className='m-8'>
              <h1 className='text-xl mb-2 font-avenir font-bold hover:underline'><a href="lessonplans/waldron_pma_lesson.pdf" target="_blank">What happened to the Mexican ranchos of California?</a></h1>
            </div>  
          </div>
        </div>
        <h1 className='font-canela text-xl mb-1 mt-2'>High School</h1>
        <div className='flex flex-col'>
          <div className=' bg-pma-pink mb-5'>
            <div className='m-8'>
              <p className='mr-1 font-avenir text-sm'>Coming soon</p>
            </div>  
          </div>
        </div>
      </div>
      <div className='flex justify-center pt-8 pb-20 relative'>
        <div className='hidden sm:flex justify-center'>
          <div className='flex justify-center w-4/6 relative z-10 bg-pma-orange p-3'>
          <div className=' h-16 w-3 absolute bottom-0 right-0 bg-white' />
          <div className='h-3 w-16 absolute bottom-0 right-0 bg-white' />
          <div className='h-12 w-3 absolute top-0 left-0 bg-white' />
          <div className='h-3 w-16 absolute top-0 left-0 bg-white' />
            <img src='/images/educationContact.png' alt='' className='w-full relative'></img>
            <div className="absolute bottom-5 right-5 bg-white p-2">
              <div className='mx-auto px-4 w-72'>
                <a target="_blank" rel="noreferrer" href='https://mailchi.mp/8bb2d79e8e70/sign-up' className='flex'>
                  <h1 className='font-canela text-2xl pt-1 hover:underline'>Got Lessons?</h1>
                  <img src='/icons/arrow-right.svg' alt='' className='w-8 ml-4' />
                </a>
                <h2 className='font-avenir text-m pb-2'>Let us know if you’re using Picturing Mexican America in the classroom. We'd love to feature your lesson plan here.</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='flex sm:hidden'>
            <div className='flex-justify center outline'>
              <div className='mx-auto px-4 py-2 w-72'>
                <a target="_blank" rel="noreferrer" href='https://mailchi.mp/8bb2d79e8e70/sign-up' className='flex'>
                  <h1 className='font-canela text-2xl pt-1 hover:underline'>Got Lessons?</h1>
                  <img src='/icons/arrow-right.svg' alt='' className='w-8 ml-4' />
                </a>
                <h2 className='font-avenir text-m pb-2'>Let us know if you’re using Picturing Mexican America in the classroom. We'd love to feature your lesson plan here.</h2>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Education
