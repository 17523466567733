import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const About = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function newsItem(url, image, source, title) {
    return (
            <div className='m-2 sm:m-2 md:m-4 flex justify-center'>
              <div>
                <a href={url}>
                  <img src={image} loading='lazy' alt='' className='h-64 block w-auto'/>
                </a>
                <h1 className='font-canela pt-1'>
                 {source}
                </h1>
                <p className='font-avenir text-s'>
                 {title}
                </p>
                <a className="text-pma-dark-orange" href={url}>Read Story</a>
              </div>
            </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={`${className} bg-gray-600 p-2 rounded-full`}
            style={{
                ...props.style,
                display: "block",
                width: "40px",
                height: "40px",
                right: "10px", // Adjust position
                top: "40%",
                zIndex: 1
            }}
            onClick={onClick}
        >
        <img alt="" src="/icons/next.svg"/>
        </div>
      );
    }
    
  function SamplePrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div className={`${className} bg-gray-600 p-2 rounded-full`}
                style={{
                    ...props.style,
                    display: "block",
                    width: "40px",
                    height: "40px",
                    left: "10px", // Adjust position
                    top: "44%",
                    transform: "scale(-1, -1)",
                    zIndex: 1
                }}
                onClick={onClick}
            >
            <img alt="" src="/icons/next.svg"/>
        </div>
      );
    }


  return (
    <div>
      <div className='grid grid-cols-1 lg:grid-cols-2 px-5 md:px-28 mb-20 gap-x-12'>
          <h1 className='font-canela text-5xl mt-8'>
            We’re looking back at history to help us understand our present!
          </h1>
        <div className='mt-8'>
          <p className='text-s'>
              Picturing Mexican America is a cluster of digital humanities projects designed and run by Marissa López, Professor of English and Chicana/o and Central American Studies at UCLA. We are 
              committed to illuminating the long, Mexican history of Los Angeles that’s been systemically erased through centuries of white, cultural
              supremacy. At PMA’s core is a mobile app, which we developed in collaboration with the Los Angeles Public Library, that displays images of 19th century, Mexican Los Angeles to users based on their location.
              PMA is a research and teaching hub open to partnerships
              with local organizations.
          </p>
        </div>
      </div>
      <div className='flex justify-around mb-16 px-5 md:px-24'>
        <img src='/images/about1.png' loading='lazy' alt=''/>
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-2 px-5 md:px-24 mb-16 gap-10'>
        <div>
            <h1 className='font-canela text-3xl'>Who We Are</h1>
            <p className='font-avenir'>
    It takes a village to bring PMA to life. Our team comprises students, educators, volunteers, journalists, researchers, librarians, activists, and developers. Our UCLA undergraduates through 2024 are: Julia Awad, Bianca Bravo, and Adrian Hernandez. Our graduate student researchers are Valerie Barrios (UCLA) and Miriam Santana (UT Austin). We rely on the expertise of: librarians including Ani Boyadjian and Rose Knopka at Los Angeles Public Library and Jennifer Osorio, Jet Jacobs, and Lizeth Ramirez at UCLA Library; communications expert Yvonne Condes; and software engineer Steve Merel.
            </p>
        </div>
        <div>
            <h1 className='font-canela text-3xl'>What’s Next</h1>
            <p className='font-avenir'>
    We’re excited about our exhibition - “Layered Lands: Synchronous Stories of Greater Los Angeles” – which is set to open in Summer 2024 in the Annenberg Gallery at Central Library in Downtown Los Angeles. We're creating more lesson plans to support teachers, and we continue to add content to our app. Finally, we hope to expand our work beyond Los Angeles and are eager to partner with you.
            </p>
        </div>
      </div>
      <div className='mb-16 mx-5 md:mx-16'>
        <h1 className='font-avenir font-bold'>MOBILE APP</h1>
        <div className='border-t border-gray-900 mt-2 mb-4 w-auto'/>
        <div className='grid grid-cols-1 lg:grid-cols-2'>
            <div className='flex justify-around mb-2'>
              <img src='/images/appprev.png' loading='lazy' alt='' /> 
            </div>
            <div className='px-2 md:px-6 sm:mt-4 lg:mt-0'>
                <p className='mt-4 font-avenir'>
    At PMA’s core is a mobile app, which we developed in collaboration with the Los Angeles Public Library.  Among other cool features, the app uses geodata to display archival images of 19th century, Mexican Los Angeles to users. Check it out for iOS and Android!
                </p>
    <a className="inline-block h-16" href="https://apps.apple.com/us/app/picturing-mexican-america/id1567850782?itsct=apps_box_badge&amp;itscg=30200" >
        <img className = "p-2.5 h-16" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1707523200"/>
    </a>
    <a className="inline-block" href='https://play.google.com/store/apps/details?id=edu.ucla.pma&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' >
        <img className = "h-16" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
    </a>
            </div>
        </div>
      </div>
      <div className='mx-5 md:mx-16'>
        <h1 className='font-avenir font-bold'>COLLABORATIONS</h1>
        <div className='border-t border-gray-900 mt-2 mb-4 w-auto'/>
        <div className='grid grid-cols-1 lg:grid-cols-2 mb-4'>
            <div className='-order-first lg:order-first mb-1'>
                <h1 className='font-canela text-2xl'>LA Explorers Club</h1>
                <p className='mt-2 font-avenir'>"Daily Life in Early Los Angeles" is a self-guided bike tour through nineteenth-century Mexican LA. 
                    We’re not gonna lie: 19th century Mexican LA was neither idyllic, egalitarian, or anti-racist, but it’s 
                    important to understand that it WAS here. We hope this ride untagles some of those threads.
                </p>
                <a className="text-pma-dark-orange" href='https://laexplorersclub.com'>Learn More</a>
            </div>
            <div className='flex justify-around'>
              <a href='https://laexplorersclub.com'><img src='/images/bikelogo.png' loading='lazy' alt='' /></a>
            </div>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2'>
            <div className='flex justify-around px-4 mb-2'>
              <a href='https://826la.org/students-time-travel-to-mexican-america-in-writing-workshop/'><img src='/images/826LA.png' loading='lazy' alt='' /></a>
            </div>
            <div>
                <h1 className='font-canela text-2xl'>826LA</h1>
                <p className='mt-2 font-avenir'>
                    We partnered with 826LA for their Summer Writers Workshop for middle and high 
                    school students to lead them through a week of writing and creating art, drawing 
                    inspiration from the history, present, and future of LA.
                </p>
                <a className="text-pma-dark-orange" href='https://826la.org/students-time-travel-to-mexican-america-in-writing-workshop/'>Learn More</a>
            </div>
        </div>
      </div>
      <div className='px-4 sm:px-8 md:px-18 lg:px-32'>
        <div className=' bg-pma-pink ring-1 ring-pma-orange px-10 py-10 mx-5 md:mx-20 my-20 flex flex-col justify-center items-center relative'>
            <h1 className='text-center text-3xl font-canela mb-1'>How you can get involved</h1>
              <p className='font-avenir text-center'>
                We are looking for tech volunteers, and we are always looking for 
                more information about the material we circulate. If you have programming 
                skills to share, if you recognize anyone in the picture we’re posting on 
                Instagram, or if you have a connection to the places we’ve featured, we’d
                love to hear from you.
              </p>
            <button className='bg-pma-orange px-8 rounded-lg hover:bg-orange-700  p-2 font-bold mt-4'>
              <a target="_blank" rel="noreferrer" href='https://mailchi.mp/8bb2d79e8e70/sign-up' className='text-white'>Contact Us</a>
            </button>
        </div>
      </div>
      <div className='md:mx-16'>
        <h1 className='font-avenir font-bold'>IN THE NEWS</h1>
        <div className='border-t border-gray-900 my-4 w-auto'/>

        <Slider {...settings} className='md:px-16'>

    { newsItem(
      'https://www.latimes.com/california/story/2020-09-23/picturing-mexican-america-bike-tour-latino-history-downtown-los-angeles',
      '/images/news1.png',
      'LA Times, September 2020',
      'A bike tour reveals hidden Latin history in downtown Los Angeles'
    )}
    { newsItem(
      'https://spectrumnews1.com/ca/orange-county/inside-the-issues/2021/01/08/new-bike-tour-takes-a-deep-dive-into-l-a--s-hidden-mexican-past',
      '/images/spectrum.jpg',
      'Spectrum News 1, October 2020',
      'Bike Tour Takes Deep Dive Into LA’s Hidden Mexican Past'
    )}
    { newsItem(
      'https://youtu.be/46eAEMvpOzE',
      '/images/news2.png',
      'Los Angeles Public Library, December 2020',
      'Picturing Mexican America: a digital, visual, networked history of the future'
    )}
    { newsItem(
      'https://podcasts.apple.com/us/podcast/the-wine-chisme-podcast/id1499221831?i=1000543615449',
      '/images/news3.png',
      'The Wine & Chisme Podcast, November 2021',
      'The Systematic Erasure of Mexican History with Marissa López'
    )}
    { newsItem(
      'https://www.brandeis.edu/mandel-center-humanities/events/humanists-at-work.html',
      '/images/news4.jpg',
      'Mandel Center for the Humanities, Brandeis University, November 2021',
      'What is Public Scholarship?'
    )}
    { newsItem(
      'https://youtu.be/rWF-fq4UU-0',
      '/images/news5.png',
      'En Casa Con La Plaza, La Plaza de Cultural y Artes, July 2021',
      'Picturing Mexican America'
    )}
    { newsItem(
      'https://vimeo.com/487044212', 
      '/images/news6.png', 
      'Artist Salon, The Autry Museum of the American West, December 2020', 
      'What’s Her Story? Liza Posas in Conversation with Dr. Marissa López'
    )}
    { newsItem(
      'https://www.youtube.com/watch?v=TeJKFv6TO38', 
      '/images/news7.png', 
      'En Casa Con La Plaza, La Plaza de Cultural y Artes, June 2020', 
      'Corridos, Cumbias, y Coronavirus'
    )}
    { newsItem(
      'https://soundcloud.com/c19podcast/s05e04-public-memory-and-los-angeles-latinx-c19',
      '/images/news8.png',
      'C19 Podcast, season 5, episode 4',
      'Public Memory and Los Angeles’ Latinx C19',
    )}
    { newsItem(
      'https://www.pbssocal.org/shows/lost-la/arcadia-bandini-stearns-de-baker',
      '/images/news10.png',
      'PBS SoCal, September 2021',
      'The Powerful Mexican Woman Who Helped Shape Early Santa Monica'
    )}
    { newsItem(
      'https://www.pbssocal.org/shows/lost-la/cinco-de-mayo-mexican-los-angeles-french-emigre',
      '/images/news9.png',
      'PBS SoCal, May 2021',
      'What Cinco de Mayo Has to do with the French in Early L.A.'
    )}

          </Slider>
            
        </div>

      </div>
  )
}

export default About;
