import React from 'react';

function EmailSub() {
  /*
    const[email, setEmail] = useState("");

    const handleSubmit = async(event) => {
        event.preventDefault();
    };
    */

    return (
        <div className= 'bg-pma-dark-orange h-36 flex justify-center items-center'>
    <a className="inline-block h-16" href="https://apps.apple.com/us/app/picturing-mexican-america/id1567850782?itsct=apps_box_badge&amp;itscg=30200" >
        <img className = "p-2.5 h-16" src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1707523200"/>
    </a>
    <a className="inline-block" href='https://play.google.com/store/apps/details?id=edu.ucla.pma&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' >
        <img className = "h-16" src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
    </a>

            <button className='bg-pma-orange px-4 rounded-lg text-white p-2 ml-5 font-bold uppercase'>
      <a target="_blank" rel="noreferrer" href="https://mailchi.mp/8bb2d79e8e70/sign-up">Click here to receive updates</a>
            </button>
        </div>
    );
}

export default EmailSub
