import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';

const StoryOfWeek = ({className}) => {
const [story, setStory] = useState(null);

//can probably remove this

const navigate = useNavigate();

useEffect(() => {
  const fetchStories = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/story?numStories=1`);
      setStory(res.data);
    } catch (error) {
      console.error('Failed to fetch stories:', error);
    } finally {
    }
  };

  fetchStories();
}, []);

  return (
    <div>
      <div className='flex justify-around'>
        { story ? (
            <div className='relative'>
                <img src={story[0].imageUrls[0]} alt="" className='w-116' />
                <div className="absolute top-8 left-0 bg-pma-orange text-white px-3 pt-2 pb-1 text-sm font-avenir flex items-center justify-center">
                    STORY OF THE WEEK
                </div>
                <div className="absolute bottom-8 right-0 bg-white p-2">
                    <div className='mx-auto px-4'>
                        <h1 className='font-canela text-xl py-1'>{story[0].title}</h1>
                        <button onClick={ ()=> navigate(`/stories/${story[0]._id}`, {state: story[0]})}>
                          <h2 className='font-avenir underline text-pma-dark-orange pb-2'>Read More</h2>
                        </button>
                    </div>
                </div>
            </div>
        ) : (
        <div>
            loading...
        </div>)}
      </div>
    </div>
  )
}

export default StoryOfWeek;
