import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";

function addInstagramLinks(text) {
  return text.replace(/@([A-Za-z0-9_.]{3,25})/g, `<a class="text-pma-orange hover:text-pma-dark-orange" href="https://instagram.com/$1">@$1</a>`)
             .replace(/#([A-Za-z0-9_.]{3,30})/g, `<a class="text-pma-orange hover:text-pma-dark-orange" href="https://instagram.com/explore/tags/$1">#$1</a>`)
}

function IndividualStory() {
    const { id } = useParams();
    const [story, setStory] = useState(null);
    const [error, setError] = useState(null);

    const location = useLocation();
    const storyData = location.state;
 
    useEffect(() => {
        // if data was passed from previous page
        if(storyData){
            setStory(storyData);
            console.log('story data', storyData);
        }
        // Page navigated to manually -> no data passed
        else {
            async function fetchStory() {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/story?id=${id}`);

                    // Error handling
                    if (response.status !== 200) {
                        setError(`Error: ${response.statusText}`);
                        return;
                    }

                    setStory(response.data);
                } catch (error) {
                    console.error("Error fetching story:", error);
                    setError("There was a problem fetching the story. Please try again later.");
                }
             }
            fetchStory();
        }
    }, [id, storyData]);

    return (
        <div>
            {story ? (
                <div className='flex flex-col'>
                     <div className=' bg-pma-green flex flex-col items-center text-white h-116'>
                        <h1 className='font-canela xs:text-3xl md:text-4xl pt-10'>{story.title}</h1>
              {/*<p className='font-avenir text-md text-center'>Blurb also to be Added</p>*/}
                    </div>
                    <div className='bg-white flex justify-center relative pb-10 md:pb-20'>
                        <img src={story.imageUrls.length > 0 ? story.imageUrls[0] : null} alt="" className='h-96 absolute -top-80 md:h-124 p-5 pt-10 md:absolute md:-top-96'/>
                    </div>
                    <p className='font-avenir text-s text-center pt-8 pb-4 px-10 md:px-32' dangerouslySetInnerHTML={{__html:addInstagramLinks(story.text?.en)}}></p>
                    <a className='font-avenir text-center text-sm' href={"https://instagram.com/p/"+story.shortCode}><img alt="" className="inline h-5 pr-1 pb-1" src="/icons/instagram_color.svg"/>View on Instagram</a>
                    <div className='border-t border-gray-900 my-4 w-auto mx-10 md:mx-20 pb-10'/>
                </div>
            ) : (
                <div>
                    { error ? (
                        <div className='font-avenir'>{error}</div>
                    ) : (
                        <p className='font-avenir'>Loading...</p>
                    )}
                </div>
            )}
        </div>
    )
}

export default IndividualStory;

